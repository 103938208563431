import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75ce17ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingsBar = _resolveComponent("SettingsBar")!
  const _component_PlayerHeader = _resolveComponent("PlayerHeader")!
  const _component_NoteFlowHost = _resolveComponent("NoteFlowHost")!
  const _component_Keyboard = _resolveComponent("Keyboard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SettingsBar, { ref: "settingsBar" }, null, 512),
    _createVNode(_component_PlayerHeader, { ref: "header" }, null, 512),
    _createVNode(_component_NoteFlowHost, { ref: "flow" }, null, 512),
    _createVNode(_component_Keyboard, { ref: "keyboard" }, null, 512)
  ]))
}