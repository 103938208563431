import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-201e5147"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "banner" }
const _hoisted_2 = { class: "flex col align-start g0" }
const _hoisted_3 = { class: "flex align-center g0" }
const _hoisted_4 = {
  class: "hbold",
  style: {"font-size":"20px","line-height":"23px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IvoryIcon = _resolveComponent("IvoryIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_IvoryIcon, {
          size: 35,
          icon: "msg"
        }),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.title), 1)
      ]),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("span", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close())),
      class: "close"
    }, "×")
  ]))
}