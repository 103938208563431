
import { defineComponent } from "vue";
import { getHomeState } from "@/state/HomeState";
import GenreRecord from "@/model/genres/GenreRecord";
import GenreRow from "@/components/home/GenreRow.vue";

export default defineComponent({
  data() {
    var state = getHomeState();
    return {
      state: state,
    };
  },

  props: {
    click: {
      type: Function,
    },
  },

  components: {
    GenreRow,
  },
  mounted() {},
  methods: {
    onClick(genre: GenreRecord) {
      if (this.click != null) {
        this.click(genre);
      }
    },
  },
});
