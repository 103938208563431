import * as Drawing from "@/graphics/DrawingHelper";
import IvoryNote from "@/model/songs/IvoryNote";
import Sheet from "./Sheet";
import * as ChordManager from "@/managers/ChordManager";
import * as SheetManager from "@/managers/SheetManager";
import * as ColorUtils from "@/utils/ColorUtils";
import SheetNote from "./SheetNote";

export default class Staff {
  public static GapBetweenLines = 17;

  public static GapBetweenStaff = Staff.GapBetweenLines * 10;

  public static StaffHeight = this.GapBetweenStaff + this.GapBetweenLines * 4;

  public static SignatureWidth = 150;

  id: number;
  y: number;
  public paddingX: number;
  ctx: CanvasRenderingContext2D;

  A4TrebleY: number | null = null;

  A4BassY: number | null = null;

  notes: SheetNote[] = [];

  trebleKeyImage: HTMLImageElement | null = null;

  constructor(
    id: number,
    ctx: CanvasRenderingContext2D,
    y: number,
    paddingX: number
  ) {
    this.id = id;
    this.y = y;
    this.paddingX = paddingX;
    this.ctx = ctx;

    this.A4TrebleY = this.getY() + 2 * Staff.GapBetweenLines;

    this.A4BassY =
      this.getY() + Staff.GapBetweenStaff + 3 * Staff.GapBetweenLines;
  }

  draw() {
    this.drawMesures();

    this.ctx.strokeStyle = "black";
    this.ctx.lineWidth = 1;
    this.drawTrebleKey();
    this.drawBassKey();

    this.ctx.lineWidth = 2;

    this.ctx.beginPath();
    this.ctx.moveTo(this.paddingX, this.getY());
    this.ctx.lineTo(this.paddingX, this.getY() + Staff.StaffHeight);
    this.ctx.stroke();

    for (let note of this.notes) {
      note.draw(this.ctx);
    }

    /* Drawing.drawText(
      this.ctx,
      "4",
      this.paddingX + 120,
      this.getY() + 30,
      "40px",
      "black"
    );
    Drawing.drawText(
      this.ctx,
      "4",
      this.paddingX + 120,
      this.getY() + 60,
      "40px",
      "black"
    ); */
  }

  visible() {
    return (
      this.getY() + Staff.StaffHeight > 0 &&
      this.getY() < this.ctx.canvas.height
    );
  }

  getY() {
    return Sheet.scrollY + this.y;
  }
  drawMesures() {
    this.ctx.lineWidth = 1;

    let measureWidth =
      (this.getTotalWidth() - Staff.SignatureWidth) / Sheet.MeasurePerStaff!;

    let x = Staff.SignatureWidth + this.paddingX;

    for (let i = 0; i < Sheet.MeasurePerStaff + 1; i++) {
      Drawing.drawLine(
        this.ctx,
        x,
        this.getY(),
        x,
        this.getY() + Staff.StaffHeight,
        "rgb(180,180,180)"
      );
      x += measureWidth;
    }
  }

  public getTotalWidth() {
    return this.ctx.canvas.width - this.paddingX * 2;
  }

  drawTrebleKey() {
    let widthTotal = this.ctx.canvas.width - this.paddingX;

    Drawing.drawImage(
      this.ctx,
      "/ico/treble-key.svg",
      70,
      this.getY() - 30,
      80,
      140
    );

    for (let i = 0; i < 5; i++) {
      this.ctx.beginPath();
      this.ctx.moveTo(this.paddingX, this.getY() + i * Staff.GapBetweenLines);
      this.ctx.lineTo(widthTotal, this.getY() + i * Staff.GapBetweenLines);
      this.ctx.stroke();
    }
  }
  drawBassKey() {
    let widthTotal = this.ctx.canvas.width - this.paddingX;

    Drawing.drawImage(
      this.ctx,
      "/ico/bass-key.svg",
      70,
      this.getY() + Staff.GapBetweenStaff - 45,
      100,
      160
    );

    for (let i = 0; i < 5; i++) {
      this.ctx.beginPath();
      this.ctx.moveTo(
        this.paddingX,
        this.getY() + Staff.GapBetweenStaff + i * Staff.GapBetweenLines
      );
      this.ctx.lineTo(
        widthTotal,
        this.getY() + Staff.GapBetweenStaff + i * Staff.GapBetweenLines
      );
      this.ctx.stroke();
    }
  }
  addNote(note: IvoryNote) {
    console.log(note.start);
    this.notes.push(new SheetNote(this, note));
  }
}
