
import IvorySong from "@/model/songs/IvorySong";
import { defineComponent } from "vue";
import { getState } from "@/state/IvoryState";
import { HalfCircleSpinner } from "epic-spinners";
import * as IvoryApi from "@/IvoryApi";
import * as DOMUtils from "@/utils/DOMUtils";
import * as Modals from "@/utils/Modals";

export default defineComponent({
  props: {
    song: {
      required: true,
      type: null,
    },
    tailing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: { HalfCircleSpinner },

  data() {
    return {
      state: getState(),
      DOMUtils: DOMUtils,
    };
  },
  methods: {
    getFavIcon() {
      let state = getState();

      let exists = state.favorites?.some((x) => x.id == this.song.id);

      if (exists) {
        return "star-fav";
      } else {
        return "star-empty-fav";
      }
    },
    async favoriteClick() {
      this.song.pendingStar = true;
      let state = getState();

      var refs = <any>this.$refs;

      let exists = await IvoryApi.toggleFavorite(this.song.id!);

      if (!exists) {
        let index = state.favorites?.indexOf(this.song);
        state.favorites?.splice(index!, 1);
        refs.favIcon.source = "/ico/star-empty-fav.svg";
      } else {
        state.favorites?.push(this.song);

        refs.favIcon.source = "/ico/star-fav.svg";
      }

      this.song.pendingStar = false;
    },
    sheetClick() {
      let state = getState();
      state.openSheet(this.song);
    },
    downloadClick() {
      let state = getState();
      state.downloadMidi(this.song!);
    },
    async rateSong(n: number) {
      await IvoryApi.rateSong(this.song!.id!, n);
    },
    getVideoId(url: string) {
      const regex =
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      const match = url.match(regex);
      return match ? match[1] : null;
    },
  },
});
