
import { defineComponent } from "vue";
import GenreRecord from "@/model/genres/GenreRecord";
import { getHomeState } from "@/state/HomeState";

export default defineComponent({
  computed: {
    card(): any {
      return {
        minWidth: "300px",
        minHeight: "150px",
      };
    },
  },
  props: {
    genre: {
      type: GenreRecord,
      required: true,
    },
    image: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
});
