
import { defineComponent, ref } from "vue";
import IvoryButton from "./IvoryButton.vue";
import { getState } from "@/state/IvoryState";
import * as DOMUtils from "@/utils/DOMUtils";
import * as ColorUtils from "@/utils/ColorUtils";

export default defineComponent({
  expose: ["open", "close", "toggle"],

  mounted() {
    document.addEventListener("keydown", this.onKeyDown);
  },
  data() {
    return {
      isOpen: false,
    };
  },
  props: {
    closeButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    onClosed: {
      type: Function,
      required: false,
      default: null,
    },
  },

  methods: {
    onKeyDown(event: any) {
      if (event.key == "Escape" && this.isOpen) {
        this.close();
      }
    },
    toggle() {
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }
      return this.open;
    },
    close() {
      this.$el.style.display = "none";
      this.isOpen = false;

      if (this.closeButton) {
        this.$el.removeEventListener("mousedown", this.onClick);
      }

      if (this.onClosed != null) {
        this.onClosed();
      }
    },

    onClick(event: MouseEvent) {
      var target = <HTMLElement>event.target;

      if (
        !DOMUtils.isDescendantOf(target, this.$el.firstChild) &&
        target != this.$el.firstChild
      ) {
        this.close();
      }
    },
    open() {
      this.$el.style.display = "flex";
      this.isOpen = true;
      if (this.closeButton) {
        this.$el.addEventListener("mousedown", this.onClick);
      }
    },
  },
  components: { IvoryButton },
});
