
import { defineComponent } from "vue";
import { getState } from "@/state/IvoryState";
import IvorySong from "@/model/songs/IvorySong";
import * as IvoryApi from "@/IvoryApi";
import SongRow from "./SongRow.vue";
import router from "@/router";
import ProcessingTask from "@/model/ProcessingTask";
import TranscriptionRow from "@/components/home/TranscriptionRow.vue";
import TranscriptionResult, {
  TranscriptionStatus,
} from "@/model/TranscriptionResult";
import GenreRow from "@/components/home/GenreRow.vue";
import { getHomeState } from "@/state/HomeState";
import GenreRecord from "@/model/genres/GenreRecord";
import { TranscriptionSource } from "@/model/TranscriptionSource";
import * as Modals from "@/utils/Modals";
import mixpanel from "mixpanel-browser";

export default defineComponent({
  data() {
    var state = getState();
    var homeState = getHomeState();
    return {
      TranscriptionSource: TranscriptionSource,
      tasks: [] as ProcessingTask[] | null,
      state: state,
      homeState: homeState,
      interval: null as number | null,
      selectedGenre: null as GenreRecord | null,
      transcriptionSource: null as TranscriptionSource | null,
      myUploads: null as IvorySong[] | null,
    };
  },

  mounted() {
    this.interval = setInterval(this.updateTasks, 1000);
    this.updateTasks();
  },
  unmounted() {
    clearInterval(this.interval!);
  },
  methods: {
    async removeSong(song: IvorySong) {
      song.pendingRemove = true;
      await IvoryApi.removeSong(song.id);
    },
    onSongClick(song: IvorySong) {
      var state = getState();

      state.querySongId = song.id;
      router.push("player");
    },

    onGenreClick(genre: GenreRecord) {
      var refs = <any>this.$refs;
      this.selectedGenre = genre;
    },
    async updateTasks() {
      var tasks = await IvoryApi.getRunningTasks();

      this.tasks = tasks!.reverse()!;

      this.myUploads = await IvoryApi.getMyUploads();
    },

    async onFileUpload(event: any) {
      var refs = <any>this.$refs;
      var file = event.target.files[0];

      this.transcriptionSource = TranscriptionSource.File;
    },
    handleTranscriptionResult(result: TranscriptionResult) {
      if (result?.status == TranscriptionStatus.Ok) {
        mixpanel.track("Transcribe", {});

        this.updateTasks();

        if (!this.state.account?.subscribed) {
          setTimeout(() => {
            Modals.open(
              "We offer a high-resolution song transcription service. Your transcription is running on low priority queue <b>without subscription</b>. It can take between 1 to 10 minutes depending on the total length of the piece, subscribe for a <b>faster transcription</b>.",
              "No subscription"
            );
          }, 2000);
        }
      } else if (result?.status == TranscriptionStatus.AlreadyExists) {
        Modals.open(
          "This piece was already transcribed, you can access it using search.",
          "Informations"
        );
      } else if (result?.status == TranscriptionStatus.AlreadyProcessing) {
        Modals.open(
          "This piece is already processing. Please wait until its complete.",
          "Informations"
        );
      } else if (result?.status == TranscriptionStatus.InvalidUrl) {
        Modals.open(
          "The link you provided is not a valid youtube link.",
          "Informations"
        );
      } else if (result.status == TranscriptionStatus.MidiImported) {
        Modals.open(
          "Your MIDI file has been added to the library.",
          "Informations"
        );
      } else if (result.status == TranscriptionStatus.NotSubscribed) {
        Modals.open(
          "You need to be <b>subscribed</b> to to perform this action.",
          "Informations"
        );
      }
    },
    async transcribe() {
      var refs = <any>this.$refs;

      if (this.selectedGenre == null) {
        Modals.open(
          "Please select a genre before transcribing.",
          "Informations"
        );
        return;
      }

      if (this.transcriptionSource == TranscriptionSource.Youtube) {
        var url = refs.youtubeInput.getValue();

        var result = await IvoryApi.requestProcessingYoutube(
          url,
          this.selectedGenre!.id
        );

        this.handleTranscriptionResult(result!);
      } else if (this.transcriptionSource == TranscriptionSource.File) {
        var file = refs.fileInput.getFile();
        var name = refs.name.getValue();
        var author = refs.author.getValue();

        if (name.trim() == "" || author.trim() == "") {
          Modals.open(
            "Please specify a title and an author before transcribing.",
            "Informations"
          );
          return;
        }

        let result = await IvoryApi.uploadFile(
          name,
          author,
          this.selectedGenre!.id,
          file
        );

        this.handleTranscriptionResult(result!);

        this.updateTasks();
      }
    },
    async onYoutubeReady() {
      this.transcriptionSource = TranscriptionSource.Youtube;
    },
  },
  components: { TranscriptionRow, GenreRow, SongRow },
});
