import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16c00686"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle('width:' + _ctx.size + 'px;height:' + _ctx.size + 'px'),
    class: "ivory-icon"
  }, [
    _createElementVNode("img", {
      width: _ctx.size,
      height: _ctx.size,
      src: _ctx.source
    }, null, 8, _hoisted_1),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 4))
}