import IvoryArtist from "./songs/IvoryArtist";
import IvorySong from "./songs/IvorySong";

export default class SearchResults {

    public artists: IvoryArtist[] = [];

    public songs: IvorySong[] = [];

    public getLength(): number {
        return this.artists.length + this.songs.length;
    }

    constructor(artists: IvoryArtist[] = [], songs: IvorySong[] = []) {
        this.artists = artists;
        this.songs = songs;
    }

}