
import { defineComponent } from "vue";
import { getState } from "@/state/IvoryState";
import IvorySong from "@/model/songs/IvorySong";
import * as IvoryApi from "@/IvoryApi";
import SongRow from "./SongRow.vue";
import router from "@/router";
import * as Constants from "@/Constants";

export default defineComponent({
  async mounted() {
    var refs = <any>this.$refs;

    this.state.account = await IvoryApi.getLoggedAccount();

    if (this.state.settings.backgroundUrl != null)
      refs.backgroundInput.setValue(this.state.settings.backgroundUrl);
  },
  data() {
    var state = getState();
    return {
      state: state,
    };
  },

  methods: {
    getSubscriptionDaysLeft() {
      const nowUtc = new Date(new Date().toISOString());
      const subscriptionEnd = new Date(
        this.state.account?.subscriptionEndTime!
      );

      // Calculate the difference in milliseconds
      const differenceInMilliseconds = Math.abs(
        subscriptionEnd.getTime() - nowUtc.getTime()
      );

      // Convert milliseconds to days (1000 milliseconds * 60 seconds * 60 minutes * 24 hours)
      const millisecondsInADay = 24 * 60 * 60 * 1000;
      const differenceInDays = differenceInMilliseconds / millisecondsInADay;

      return Math.ceil(differenceInDays);
    },
    goDiscord() {
      window.open(Constants.DiscordLink, "_blank");
    },
    async updateBackground(value: string) {
      this.state.settings.backgroundUrl = value;
      await IvoryApi.updateAccountSettings(this.state.settings);
      this.state.backgroundUrl = value;
    },
  },
  components: { SongRow },
});
