
import { defineComponent, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getHomeState } from "@/state/HomeState";
import { HomePageEnum } from "@/state/HomePageEnum";
import { getState } from "@/state/IvoryState";
import * as IvoryApi from "@/IvoryApi";
import * as Constants from "@/Constants";

export default defineComponent({
  expose: ["onSongLoad"],
  data() {
    var state = getHomeState();
    var appState = getState();

    return {
      HomePageEnum: HomePageEnum,
      state: state,
      appState: appState,
    };
  },
  setup() {
    var route = useRoute();
    var router = useRouter();

    function isActive(page) {
      return getHomeState().page == page;
    }

    return {
      router,
      isActive,
    };
  },
  methods: {
    async logout() {
      await IvoryApi.logout();
      this.appState.destroySession();
      this.router.replace({ name: "login" });
    },
    login() {
      this.router.replace({ name: "login" });
    },
    onDiscordClick() {
      window.open(Constants.DiscordLink, "_blank");
    },
    onInstagramClick() {
      window.open(Constants.InstagramLink, "_blank");
    },
    onTiktokClick() {
      window.open(Constants.TiktokLink, "_blank");
    },
  },
});
