
import { defineComponent } from "vue";
import { getState } from "@/state/IvoryState";
import { useRouter } from "vue-router";
import mixpanel from "mixpanel-browser";
import * as Modals from "@/utils/Modals";
import * as IvoryApi from "@/IvoryApi";
import Sheet from "@/components/sheets/Sheet";
import IvorySong from "@/model/songs/IvorySong";
import VF from "vexflow";

export default defineComponent({
  data() {
    return {
      state: getState(),
      loaded: false,
      sheet: null as Sheet | null,
      Sheet: Sheet,
    };
  },
  setup() {
    var router = useRouter();
    return {
      router: router,
    };
  },
  computed: {},

  methods: {
    goHome() {
      this.router.push({ name: "home" });
    },
  },

  async mounted() {
    Sheet.scrollY = 0;

    mixpanel.track("Sheet view", {});

    let sheet = await IvoryApi.getSheet(this.state.querySongId!);

    console.log(sheet);

    var refs = <any>this.$refs;

    const element = refs.header as HTMLElement;
    const rect = element.getBoundingClientRect();

    const canvas = refs.canvas;
    canvas.width = window.innerWidth;
    canvas.height =
      window.innerHeight - (rect.top + window.scrollY + rect.height);

    var renderer = new VF.Renderer(canvas, VF.Renderer.Backends.CANVAS);

    renderer.resize(canvas.width, canvas.height);

    var context = renderer.getContext();

    context.setFont("Arial", 10, "").setBackgroundFillStyle("black");
    var stave1 = new VF.Stave(10, 40, 400);

    stave1
      .addClef("treble")
      .addTimeSignature(
        sheet?.numberOfBeatsPerMeasure + "/" + sheet?.beatNoteValue
      )
      .setTempo({ bpm: 65, duration: "q", dots: 0, name: "Tempo" }, 0);

    var notes1 = [
      new VF.StaveNote({ clef: "treble", keys: ["c/4"], duration: "q" }),
      new VF.StaveNote({ clef: "treble", keys: ["d/4"], duration: "q" }),
      new VF.StaveNote({ clef: "treble", keys: ["b/4"], duration: "qr" }),
      new VF.StaveNote({
        clef: "treble",
        keys: ["c/4", "e/4", "g/4"],
        duration: "q",
      }),
    ];

    var voice1 = new VF.Voice({ num_beats: 4, beat_value: 4 });
    voice1.addTickables(notes1);

    var formatter = new VF.Formatter()
      .joinVoices([voice1])
      .format([voice1], 400);
    stave1.setContext(context).draw();
    voice1.draw(context, stave1);

    // Second Measure
    var stave2 = new VF.Stave(stave1.getWidth() + stave1.getX(), 40, 400);

    var notes2 = [
      new VF.StaveNote({ clef: "treble", keys: ["g/4"], duration: "h" }),
      new VF.StaveNote({ clef: "treble", keys: ["f/4"], duration: "h" }),
    ];

    var voice2 = new VF.Voice({ num_beats: 4, beat_value: 4 });
    voice2.addTickables(notes2);

    var formatter2 = new VF.Formatter()
      .joinVoices([voice2])
      .format([voice2], 400);
    stave2.setContext(context).draw();
    voice2.draw(context, stave2);

    this.loaded = true;
  },
});
