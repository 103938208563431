export default class SoundElement {
  id: number = 0;
  dynamic: string = "piano";
  noteNumber: number = 0;

  constructor(id: number, dynamic: string, noteNumber: number) {
    this.id = id;
    this.dynamic = dynamic;
    this.noteNumber = noteNumber;
  }
}
