import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d92b17f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "keys" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Key = _resolveComponent("Key")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(88, (index) => {
      return _createElementVNode("div", { key: index }, [
        _createVNode(_component_Key, {
          ref_for: true,
          ref: index.toString(),
          number: index - 1
        }, null, 8, ["number"])
      ])
    }), 64))
  ]))
}