
import { defineComponent } from "vue";
import { HalfCircleSpinner } from "epic-spinners";
import * as DOMUtils from "@/utils/DOMUtils";
import ProcessingTask from "@/model/ProcessingTask";

export default defineComponent({
  props: {
    task: {
      type: ProcessingTask,
      required: true,
    },
  },

  components: { HalfCircleSpinner },

  data() {
    return {
      DOMUtils: DOMUtils,
    };
  },
  methods: {},
});
