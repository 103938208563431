export function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}
export function rgbToHex(r: number, g: number, b: number) {
  r = Math.round(r);
  g = Math.round(g);
  b = Math.round(b);
  return (
    "#" +
    ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
  );
}

export function randomColorRgb(minimumDelta: number = 0) {
  let r = Math.floor(Math.random() * (255 - minimumDelta + 1)) + minimumDelta;
  let g = Math.floor(Math.random() * (255 - minimumDelta + 1)) + minimumDelta;
  let b = Math.floor(Math.random() * (255 - minimumDelta + 1)) + minimumDelta;

  return {
    r: r,
    g: g,
    b: b,
  };
}
export function darkenColor(hex: string, percent: number) {
  // Validate the input
  if (typeof hex !== "string" || !/^#[0-9A-Fa-f]{6}$/.test(hex)) {
    throw new Error("Invalid hex color format. Use #RRGGBB");
  }

  if (typeof percent !== "number" || percent < 0 || percent > 100) {
    throw new Error("Percentage should be a number between 0 and 100.");
  }

  // Remove the '#' from the beginning of the hex string
  hex = hex.slice(1);

  // Convert hex to RGB
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Calculate the darkened RGB values
  r = Math.round(r * (1 - percent / 100));
  g = Math.round(g * (1 - percent / 100));
  b = Math.round(b * (1 - percent / 100));

  // Convert back to hex
  let rS = r.toString(16).padStart(2, "0");
  let gS = g.toString(16).padStart(2, "0");
  let bS = b.toString(16).padStart(2, "0");

  // Return the darkened color as hex
  return `#${rS}${gS}${bS}`;
}

export function brightenColor(hex: string, percent: number) {
  // Validate the input
  if (typeof hex !== "string" || !/^#[0-9A-Fa-f]{6}$/.test(hex)) {
    throw new Error("Invalid hex color format. Use #RRGGBB");
  }

  if (typeof percent !== "number" || percent < 0 || percent > 100) {
    throw new Error("Percentage should be a number between 0 and 100.");
  }

  // Remove the '#' from the beginning of the hex string
  hex = hex.slice(1);

  // Convert hex to RGB
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Calculate the brightened RGB values
  r = Math.min(Math.round(r + r * (percent / 100)), 255);
  g = Math.min(Math.round(g + g * (percent / 100)), 255);
  b = Math.min(Math.round(b + b * (percent / 100)), 255);

  // Convert back to hex
  let rS = r.toString(16).padStart(2, "0");
  let gS = g.toString(16).padStart(2, "0");
  let bS = b.toString(16).padStart(2, "0");

  // Return the brightened color as hex
  return `#${rS}${gS}${bS}`;
}

export function changeAlpha(rgba, alpha) {
  // Validate alpha value
  if (alpha < 0 || alpha > 1) {
    throw new Error("Alpha value must be between 0 and 1.");
  }

  // Regular expression to match the rgba color components
  const rgbaRegex = /^rgba\((\d+),\s*(\d+),\s*(\d+),\s*(0?\.\d+|1|0)\)$/;

  // Match the input RGBA string
  const match = rgba.match(rgbaRegex);
  if (!match) {
    throw new Error("Invalid RGBA color string.");
  }

  // Extract the red, green, and blue components
  const red = match[1];
  const green = match[2];
  const blue = match[3];

  // Construct the new RGBA color string with the updated alpha
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
}
