
import { defineComponent } from "vue";

export default defineComponent({
  props: ["modelValue", "change"],
  emits: ["update:modelValue"],
  expose: ["getValue"],

  methods: {
    getValue(): boolean {
      var input = this.$el.childNodes[this.$el.childNodes.length - 2];
      return input.checked;
    },
    onChange() {
      this.$emit("update:modelValue", this.getValue());

      if (this.change != null) {
        this.change(this.getValue());
      }
    },
  },
});
