import IvorySong from "@/model/songs/IvorySong";
import IvorySongData from "@/model/songs/IvorySongData";
import Staff from "./Staff";
import * as Drawing from "@/graphics/DrawingHelper";

export default class Sheet {
  public static scrollY: number = 0;

  public static MeasurePerStaff = 5;

  public static MeasureDurationSeconds: number | null = null;

  public static GetStaffDurationSeconds(): number {
    var total = this.MeasureDurationSeconds! * this.MeasurePerStaff;
    return total;
  }

  public static OffsetY = 150;

  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  song: IvorySong | null = null;
  staffs: Staff[];
  transposeDelta: number = 0;

  numberOfBeatsPerMeasure: number = 4;

  beatNoteValue: number = 4;

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    this.ctx = canvas.getContext("2d")!;
    this.staffs = [];
    this.canvas.addEventListener("wheel", (event) => this.onScroll(event));
  }

  private onScroll(event: WheelEvent) {
    event.preventDefault();

    let deltaY = event.deltaY;

    if (Sheet.scrollY - deltaY > 0) {
      Sheet.scrollY = 0;
      return;
    }

    Sheet.scrollY -= deltaY;

    this.draw();
  }

  loadSong(song: IvorySong) {
    this.song = song;

    Sheet.MeasureDurationSeconds = (this.numberOfBeatsPerMeasure / 65) * 60;

    console.log(
      "Time signature : " +
        this.numberOfBeatsPerMeasure +
        "/" +
        this.beatNoteValue
    );

    console.log("Tempo : " + song.data?.tempo);

    console.log("Measure duration seconds : " + Sheet.MeasureDurationSeconds);

    if (song.data?.notes.every((x) => !x.leftHand)) {
      for (let note of song.data.notes) {
        if (note.number < 30) {
          note.leftHand = true;
        }
      }
    }

    let staffCount =
      song.data?.totalDuration! / Sheet.GetStaffDurationSeconds();

    let paddingY = 160;

    let paddingX = 50;

    let y = paddingY + Sheet.OffsetY;

    for (let i = 0; i < staffCount; i++) {
      let staff = new Staff(i, this.ctx, y, paddingX);
      this.staffs.push(staff);
      y += Staff.StaffHeight + paddingY;
    }

    /*  this.canvas.height =
      Staff.StaffHeight * staffCount +
      paddingY * (staffCount + 1) +
      Sheet.OffsetY;*/

    for (let note of this.song?.data?.notes!) {
      let staffIndex = Math.floor(note.start / Sheet.GetStaffDurationSeconds());

      let staff = this.staffs[staffIndex];

      staff.addNote(note);
    }
  }

  drawTitle() {
    const text = this.song?.name || "Unknown";

    // Set the font style
    this.ctx.font = "40px Kanit, sans-serif";

    // Measure the text width
    const textWidth = this.ctx.measureText(text).width;

    const x = (this.canvas.width - textWidth) / 2;
    this.ctx.fillStyle = "black";
    this.ctx.fillText(text, x, Sheet.scrollY + 100);
  }

  update() {
    this.draw();
    requestAnimationFrame(() => this.update());
  }
  draw() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.fillStyle = "white";
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

    this.drawTitle();

    for (let staff of this.staffs) {
      if (staff.visible()) {
        staff.draw();
      }
    }
  }
}
