
import { defineComponent, nextTick } from "vue";
import { getState } from "@/state/IvoryState";
import router from "@/router";
import Key from "@/components/player/keys/Key.vue";
import * as ChordManager from "@/managers/ChordManager";
import mixpanel from "mixpanel-browser";
import * as SoundManager from "@/managers/SoundManager";

export default defineComponent({
  mounted() {
    mixpanel.track("Chords page", {});

    this.updateKeyboardSize();
  },

  data() {
    var state = getState();

    return {
      keysCount: 32,
      state: state,
      chordName: null as string | null,
    };
  },

  methods: {
    updateKeyboardSize() {
      var refs = <any>this.$refs;

      let width = refs.pageContainer.offsetWidth;

      this.state.whiteKeyWidth = width / 22 - this.state.keyMargin;
      this.state.blackKeyWidth = this.state.whiteKeyWidth / 2;

      this.state.whiteKeyHeight = window.innerHeight * 0.27;
      this.state.blackKeyHeight = window.innerHeight * 0.165;
    },

    getPressedKeyIds() {
      var results: number[] = [];

      var refs = <any>this.$refs; //refs as data?

      if (refs == undefined) {
        return results;
      }
      for (let i = 1; i <= 88; i++) {
        var key = refs[i];
        if (key != undefined && key[0].hasInput()) {
          results.push(i);
        }
      }

      return results;
    },
    playNotes() {
      let keys = this.getPressedKeyIds();

      for (let key of keys) {
        SoundManager.play(key - 1, 70);
      }
    },
    onKeyClick(n: number) {
      if (this.hasInput(n)) {
        this.removeInput(n);
      } else {
        this.addInput(n, true, 50, false);
      }
      let pressedKeys = this.getPressedKeyIds();

      this.chordName = ChordManager.getChordName(pressedKeys, false);

      if (this.chordName == null) {
        this.chordName = ChordManager.getChordName(pressedKeys, true);
      }
    },

    removeInput(noteNumber: number) {
      var refs = <any>this.$refs;
      var keyRef = refs[noteNumber + 1];

      if (keyRef != undefined) keyRef[0].removeInput();
    },
    hasInput(noteNumber: number) {
      var refs = <any>this.$refs;
      var keyRef = refs[noteNumber + 1];

      if (keyRef != undefined) {
        return keyRef[0].hasInput();
      }
      return false;
    },
    onChordNameChange(value: string) {
      this.removeInputs();

      value = value.trim();

      if (value == "") {
        return;
      } else {
        let notes = ChordManager.chordNameToNotes(value);

        console.log(notes);

        if (notes == null) {
          return;
        }
        for (let note of notes) {
          this.addInput(note + 32, true, 50, false);
        }
      }
    },

    addInput(
      noteNumber: number,
      play: boolean = true,
      velocity: number,
      leftHand: boolean
    ) {
      var refs = <any>this.$refs;
      var keyRef = refs[noteNumber + 1];
      if (keyRef != undefined) keyRef[0].addInput(play, velocity, leftHand);
    },
    removeInputs() {
      var refs = <any>this.$refs; //refs as data?
      for (let i = 32; i <= 64; i++) {
        var key = refs[i][0];
        key.removeAllInputs();
      }
    },
  },
  components: { Key },
});
