
import { defineComponent } from "@vue/runtime-core";
import Key from "./Key.vue";
import FallingNote from "@/components/player/flow/FallingNote";
import { getState } from "@/state/IvoryState";
import { Vue } from "vue-class-component";
import { computed } from "vue";
import * as ColorUtils from "@/utils/ColorUtils";

export default class Keyboard extends Vue {
  state = getState();

  mounted() {
    this.state.keyboard = this;
  }

  addInput(
    noteNumber: number,
    play: boolean = true,
    velocity: number,
    leftHand: boolean
  ) {
    var refs = <any>this.$refs;
    var keyRef = refs[noteNumber + 1];
    if (keyRef != undefined) keyRef[0].addInput(play, velocity, leftHand);
  }

  removeInput(noteNumber: number) {
    var refs = <any>this.$refs;
    var keyRef = refs[noteNumber + 1];

    if (keyRef != undefined) keyRef[0].removeInput();
  }
  removeSound(noteNumber: number) {
    var refs = <any>this.$refs;
    var keyRef = refs[noteNumber + 1];

    if (keyRef != undefined) keyRef[0].removeSound();
  }
  hasInput(noteNumber: number) {
    var refs = <any>this.$refs;
    var keyRef = refs[noteNumber + 1];

    if (keyRef != undefined) {
      return keyRef[0].hasInput();
    }
    return false;
  }
  getKeyX(number: number) {
    var refs = <any>this.$refs;
    var key = refs[number + 1];

    if (key != null) {
      return key[0].$el.offsetLeft;
    } else {
      return -50;
    }
  }

  getPressedKeyIds() {
    var results: number[] = [];

    var refs = <any>this.$refs; //refs as data?

    if (refs == undefined) {
      return results;
    }
    for (let i = 1; i <= 88; i++) {
      var key = refs[i];
      if (key != undefined && key[0].hasInput()) {
        results.push(i);
      }
    }

    return results;
  }

  removeInputs() {
    var refs = <any>this.$refs; //refs as data?
    for (let i = 1; i <= 88; i++) {
      var key = refs[i][0];
      key.removeAllInputs();
    }
  }
}
