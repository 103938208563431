import { defineStore } from "pinia";
import { HomePageEnum } from "./HomePageEnum";
import GenreRecord from "@/model/genres/GenreRecord";

interface HomeState {
  page: HomePageEnum;
  genres: GenreRecord[] | null;
  search: string;
}

const defaultState = {
  page: HomePageEnum.Search,
  genres: [],
  search: "",
};
export const getHomeState = defineStore("HomeState", {
  state: (): HomeState => {
    return Object.assign({}, defaultState);
  },
  actions: {
    navigate(page: HomePageEnum) {
      this.page = page;
    },
  },
});
