import NoteFlow from "@/components/player/flow/NoteFlow";
import { defineStore } from "pinia";

import Keyboard from "@/components/player/keys/Keyboard.vue";
import { Midi } from "@tonejs/midi";

import * as ColorUtils from "@/utils/ColorUtils";
import IvorySongData from "@/model/songs/IvorySongData";
import * as ChordManager from "@/managers/ChordManager";
import AccountRecord from "@/model/accounts/AccountRecord";
import SearchResults from "@/model/SearchResults";
import IvorySong from "@/model/songs/IvorySong";
import * as IvoryApi from "@/IvoryApi";
import * as DOMUtils from "@/utils/DOMUtils";
import SessionSettings from "@/model/accounts/SessionSettings";
import * as SoundManager from "@/managers/SoundManager";
import LearningMode from "@/model/LearningMode";
import * as Constants from "@/Constants";
import * as Modals from "@/utils/Modals";
import mixpanel from "mixpanel-browser";

interface IvoryState {
  noteR: number;
  noteG: number;
  noteB: number;

  marginBottom: number;

  keyMargin: number;
  blackKeyWidth: number;
  blackKeyHeight: number;
  whiteKeyWidth: number;
  whiteKeyHeight: number;
  noteFlow: NoteFlow | null;
  keyboard: any | null;

  settings: SessionSettings;

  backgroundUrl: string;

  timeMultiplier: number;

  initialized: boolean;

  account: AccountRecord | null;

  favorites: IvorySong[] | null;

  keyBrightnessMultiplier: number;

  querySongId: number | null;

  minimal: boolean;

  loopStart: number | null;

  loopEnd: number | null;

  learningMode: LearningMode;

  noteLHR: number;

  noteLHG: number;

  noteLHB: number;
}

const defaultState = {
  noteLHR: 84,
  noteLHG: 137,
  noteLHB: 222,
  noteR: 236,
  noteG: 132,
  noteB: 34,
  marginBottom: 5,
  keyMargin: 2,
  blackKeyWidth: 16,
  blackKeyHeight: 90,
  whiteKeyWidth: 26.5,
  whiteKeyHeight: 130,
  keyBrightnessMultiplier: 1.5,
  noteFlow: null,
  keyboard: null,
  backgroundUrl: "https://i.imgur.com/hGjxUj7.jpeg",
  timeMultiplier: 1,
  initialized: false,
  account: null,
  favorites: [],
  playedSongResult: null,
  querySongId: null,
  settings: new SessionSettings(),
  minimal: false,
  loopStart: null,
  loopEnd: null,
  learningMode: new LearningMode(),
};
export const getState = defineStore("state", {
  state: (): IvoryState => {
    return Object.assign({}, defaultState);
  },
  actions: {
    loggedIn() {
      return this.account != null;
    },
    getNoteColorHex() {
      return ColorUtils.rgbToHex(this.noteR, this.noteG, this.noteB);
    },
    getNoteColorLHHex() {
      return ColorUtils.rgbToHex(this.noteLHR, this.noteLHG, this.noteLHB);
    },
    getNoteColorLHHTML(alpha: number = 255) {
      return (
        "rgba(" +
        this.noteLHR +
        "," +
        this.noteLHG +
        "," +
        this.noteLHB +
        "," +
        alpha +
        ")"
      );
    },
    updateLHNoteColor(r: number, g: number, b: number) {
      this.noteLHR = r;
      this.noteLHG = g;
      this.noteLHB = b;
    },
    updateLHNoteColorHex(color: string) {
      var res = ColorUtils.hexToRgb(color);
      this.updateLHNoteColor(res!.r, res!.g, res!.b);
    },
    updateNoteColor(r: number, g: number, b: number) {
      this.noteR = r;
      this.noteG = g;
      this.noteB = b;
    },
    updateNoteColorHex(color: string) {
      var res = ColorUtils.hexToRgb(color);
      this.updateNoteColor(res!.r, res!.g, res!.b);
    },
    setRandomNoteColor() {
      var randomColor = ColorUtils.randomColorRgb(0);
      this.noteR = randomColor.r;
      this.noteG = randomColor.g;
      this.noteB = randomColor.b;
    },

    getNoteColorHTML(alpha: number = 255) {
      return (
        "rgba(" +
        this.noteR +
        "," +
        this.noteG +
        "," +
        this.noteB +
        "," +
        alpha +
        ")"
      );
    },
    getKeyboardWidth() {
      return (
        this.whiteKeyWidth * Constants.WhiteKeyCount +
        this.keyMargin * Constants.WhiteKeyCount
      );
    },
    getHeaderHeight() {
      return document.querySelector(".ivory-header")?.clientHeight!;
    },

    onAccountReceived() {
      this.settings = this.account?.settings!;

      if (this.settings.notesColor != null) {
        this.updateNoteColorHex(this.settings.notesColor);
      }

      if (this.settings.notesColorLeftHand != null) {
        this.updateLHNoteColorHex(this.settings.notesColorLeftHand);
      }

      if (this.settings.backgroundUrl != null) {
        this.backgroundUrl = this.settings.backgroundUrl;
      }

      mixpanel.identify(this.account?.id);

      mixpanel.people.set({
        $name: this.account?.username,
        $email: this.account?.email,
        plan: this.account?.subscribed ? "Subscribed" : "Free",
      });
    },

    computeKeyboardSize() {
      if (window.innerWidth > window.innerHeight) {
        this.whiteKeyWidth =
          window.innerWidth / Constants.WhiteKeyCount - this.keyMargin;
        this.blackKeyWidth = this.whiteKeyWidth / 2;

        this.whiteKeyHeight = window.innerHeight * 0.16;
        this.blackKeyHeight = window.innerHeight * 0.105;
      } else {
        this.blackKeyWidth = 7;
        this.whiteKeyWidth = 14;
        this.whiteKeyHeight = window.innerHeight * 0.07;
        this.blackKeyHeight = window.innerHeight * 0.045;
      }

      this.keyMargin = this.isMobileVersion() ? 1 : 2;
    },

    isMobileVersion() {
      let flag1 = "ontouchstart" in window || navigator.maxTouchPoints > 0;

      let flag2 = window.innerWidth <= 1000;

      return flag1 && flag2;
    },
    getHeader() {
      return this.keyboard?.$parent?.$refs.header as any;
    },

    getLayout() {
      return this.keyboard?.$parent as any;
    },

    destroySession() {
      if (this.noteFlow != null) {
        this.noteFlow!.destroy();
      }
      Object.assign(this, defaultState);
    },

    downloadMidi(song: IvorySong): boolean {
      if (!this.account?.subscribed && !song.free) {
        Modals.open(
          "You need to own a subscription in order to download a MIDI for paid tracks",
          "Information"
        );

        return false;
      } else {
        let url = IvoryApi.getMidiDownloadLink(song.id);
        window.open(url);
        return true;
      }
    },
    openSheet(song: IvorySong) {
      window.open(window.location.origin + "/sheet?id=" + song?.id, "_blank");
    },
    async playSong(song: IvorySong) {
      this.timeMultiplier = 1;
      this.loopStart = null;
      this.loopEnd = null;
      song.plays++;

      mixpanel.track("Play", {
        name: song.name,
      });

      var data =
        song.data == null ? await IvoryApi.requestSongData(song.id) : song.data;

      if (data != null) {
        if (this.noteFlow == null) {
          console.warn("Player canceled playback operation.");
          return;
        }
        song.data = data;

        this.noteFlow!.clearNotes();
        this.keyboard!.removeInputs();
        this.noteFlow!.loadSong(song);

        DOMUtils.writeUrlParameter("id", song.id.toString());

        if (SoundManager.isContextSuspended() || this.minimal) {
          this.getLayout().displayPlaybackOverlay();
        }
      }
    },
  },
});
