import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9de353a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenreRow = _resolveComponent("GenreRow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.genres, (genre) => {
      return (_openBlock(), _createBlock(_component_GenreRow, {
        onClick: () => _ctx.onClick(genre),
        genre: genre
      }, null, 8, ["onClick", "genre"]))
    }), 256))
  ]))
}