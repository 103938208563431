
import { defineComponent } from "@vue/runtime-core";
import * as Drawing from "@/graphics/DrawingHelper";
import FallingNote from "@/components/player/flow/FallingNote";
import * as NoteManager from "@/managers/NoteManager";
import { KeyColor } from "@/model/KeyColor";
import { getState } from "@/state/IvoryState";
import { Midi } from "@tonejs/midi";
import NoteFlow from "./NoteFlow";
import * as MidiDeviceManager from "@/managers/MidiDeviceManager";
import * as SoundManager from "@/managers/SoundManager";
import { nextTick } from "vue";

export default defineComponent({
  expose: ["togglePlaybackOverlay"],
  data() {
    return {
      state: getState(),
    };
  },
  computed: {
    flowStyle(): any {
      return {
        borderTopRightRadius: "12px",
        borderTopLeftRadius: "12px",
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${this.state.backgroundUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      };
    },
  },
  methods: {
    openInIvory() {
      window.open("https://studio.ivory-app.com", "_blank");
    },
    onWindowClick() {
      if (this.isOverlayDisplayed()) {
        this.togglePlaybackOverlay(false);
        this.state.noteFlow?.setPause(false);
      }
      window.removeEventListener("click", this.onWindowClick);
    },
    isOverlayDisplayed() {
      var refs = <any>this.$refs;
      return refs.overlay != undefined && refs.overlay.style.display == "flex";
    },
    togglePlaybackOverlay(toggle: boolean) {
      var refs = <any>this.$refs;
      refs.overlay.style.display = toggle ? "flex" : "none";
    },
    updateCanvasSize() {
      var state = getState();

      state.computeKeyboardSize();

      var refs = <any>this.$refs;
      var canvas = refs.canvas;

      nextTick(() => {
        if (canvas != null) {
          var headerHeight = state.getHeaderHeight();
          canvas.width = state.getKeyboardWidth(); // window.innerWidth;
          canvas.height =
            window.innerHeight -
            state.whiteKeyHeight -
            headerHeight -
            state.marginBottom; // IvoryHeader height
          canvas.style.width = canvas.width + "px";
        }

        state.noteFlow?.recomputeNoteX();
      });
    },
  },
  mounted() {
    var refs = <any>this.$refs;
    var canvas = refs.canvas;
    var state = getState();
    this.updateCanvasSize();
    state.noteFlow = new NoteFlow(canvas);
    refs.overlay.style.height = window.innerHeight + "px";
    window.addEventListener("click", this.onWindowClick);
    window.addEventListener("resize", this.updateCanvasSize);
  },
  components: {},
});
