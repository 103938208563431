import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26b13c91"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex col p12 panel p24" }
const _hoisted_2 = { class: "item-list flex col g6" }
const _hoisted_3 = {
  key: 0,
  class: "light"
}
const _hoisted_4 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IvoryButton = _resolveComponent("IvoryButton")!
  const _component_SongRow = _resolveComponent("SongRow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.state.favorites!.length == 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "You don't have any favorites tracks at the moment. 😎"))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.favorites?.toReversed(), (song) => {
        return (_openBlock(), _createBlock(_component_SongRow, {
          tailing: false,
          onClick: () => _ctx.onHistorySongClick(song),
          song: song,
          key: song.id
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_IvoryButton, {
                onClick: [
                  () => _ctx.removeFromFavorite(song),
                  _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                ],
                icon: "minus"
              }, null, 8, ["onClick"])
            ])
          ]),
          _: 2
        }, 1032, ["onClick", "song"]))
      }), 128))
    ])
  ]))
}