import IvoryNote from "@/model/songs/IvoryNote";
import Staff from "./Staff";
import * as SheetManager from "@/managers/SheetManager";
import Sheet from "./Sheet";
import * as ChordManager from "@/managers/ChordManager";
import * as ColorUtils from "@/utils/ColorUtils";
import * as Drawing from "@/graphics/DrawingHelper";
import { getState } from "@/state/IvoryState";

export default class SheetNote {
  ivoryNote: IvoryNote;

  color: string | null = null;

  x: number | null = null;

  y: number | null = null;

  width: number | null = null;

  height: number | null = null;

  constructor(staff: Staff, note: IvoryNote) {
    this.ivoryNote = note;
    this.computePosition(staff);
  }

  computePosition(staff: Staff) {
    let colorRightHand = getState().getNoteColorHTML(1);
    let colorLeftHand = getState().getNoteColorLHHTML(1);

    this.color = this.ivoryNote.leftHand ? colorLeftHand : colorRightHand;

    if (this.ivoryNote.leftHand) {
      let a4Num = 21;
      let num = SheetManager.getNoteRelativeNumber(this.ivoryNote.number + 1);
      let shift = num - a4Num;
      this.y = staff.A4BassY! - shift * (Staff.GapBetweenLines / 2);
    } else {
      let a4Num = 35;
      let num = SheetManager.getNoteRelativeNumber(this.ivoryNote.number + 1);
      let shift = num - a4Num;
      this.y = staff.A4TrebleY! - shift * (Staff.GapBetweenLines / 2);
    }

    let staffWidth = staff.getTotalWidth() - Staff.SignatureWidth;

    let relativeStart = this.ivoryNote.start % Sheet.GetStaffDurationSeconds();

    this.x =
      (relativeStart / Sheet.GetStaffDurationSeconds()) * staffWidth +
      staff.paddingX +
      Staff.SignatureWidth;

    this.width =
      (this.ivoryNote.duration / Sheet.GetStaffDurationSeconds()) * staffWidth;

    this.height = Staff.GapBetweenLines;

    if (this.x + this.width > staff.paddingX + staff.getTotalWidth()) {
      this.width = staff.paddingX + staff.getTotalWidth() - this.x;
    }
  }

  getY() {
    return Sheet.scrollY + this.y!;
  }
  draw(ctx: CanvasRenderingContext2D) {
    let name = ChordManager.getNoteName(this.ivoryNote.number + 1);

    ctx.shadowColor = this.color!;
    ctx.shadowBlur = 5; // Shadow blur radius
    ctx.shadowOffsetX = 2; // Horizontal shadow offset
    ctx.shadowOffsetY = 2; // Vertical shadow offset

    let color = ColorUtils.changeAlpha(this.color, 0.4);

    ctx.fillStyle = color;

    Drawing.drawRoundedRect(
      ctx,
      this.x!,
      this.getY(),
      this.width!,
      this.height!,
      10,
      false
    );

    // Define the ellipse parameters
    var radiusX = 10; // Horizontal radius
    var radiusY = 10; // Vertical radius
    var rotation = 0; // Rotation in radians
    var startAngle = 0; // Start angle in radians
    var endAngle = 4 * Math.PI; // End angle in radians

    ctx.fillStyle = ColorUtils.changeAlpha(this.color, 0.5);

    ctx.lineWidth = 1;
    ctx.strokeStyle = "rgb(50,50,50)";

    // Begin path to draw the ellipse
    ctx.beginPath();
    ctx.ellipse(
      this.x! + radiusX,
      this.getY() + radiusY,
      radiusX,
      radiusY,
      rotation,
      startAngle,
      endAngle
    );
    ctx.fill(); // Draw the outline of the ellipse

    let offset = 6;

    if (name.length > 1) {
      offset = 3;
    }
    Drawing.drawText(
      ctx,
      name!,
      this.x! + offset,
      this.getY() + 12,
      "10px",
      "white"
    );
    ctx.shadowColor = "black";
    ctx.shadowBlur = 0; // Shadow blur radius
    ctx.shadowOffsetX = 0; // Horizontal shadow offset
    ctx.shadowOffsetY = 0; // Vertical shadow offset */
  }
}
