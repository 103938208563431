
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    rate: {
      type: Number,
      required: false,
      default: 2.5,
    },

    displayRate: {
      type: Boolean,
      required: false,
      default: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: Number,
      required: false,
      default: 20,
    },

    onChange: {
      type: Function,
      required: false,
      default: undefined,
    },
    count: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    starStyle() {
      return {
        width: this.size + "px",
        height: this.size + "px",
      };
    },
  },

  data() {
    return {
      rateCurrent: this.rate,
    };
  },

  methods: {
    starClick(n: number) {
      if (this.readonly) {
        return;
      }
      this.rateCurrent = n;

      if (this.onChange != undefined) {
        this.onChange(n);
      }
    },
    getIconName(starId: number): string {
      var rt = this.readonly ? this.rate : this.rateCurrent;

      var empty = "/ico/star-empty.svg";
      var half = "/ico/star-half.svg";
      var full = "/ico/star-full.svg";

      if (rt >= 1 * starId) {
        return full;
      } else if (rt >= starId - 1 + 0.5) {
        return half;
      } else {
        return empty;
      }
    },
  },
});
