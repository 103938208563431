import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-619011a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "login-panel"
}
const _hoisted_2 = {
  key: 0,
  class: "red f14 bold"
}
const _hoisted_3 = {
  key: 1,
  class: "login-panel"
}
const _hoisted_4 = {
  key: 0,
  class: "red f14 bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IvoryLogo = _resolveComponent("IvoryLogo")!
  const _component_IvoryInput = _resolveComponent("IvoryInput")!
  const _component_IvoryButton = _resolveComponent("IvoryButton")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.loginLayout),
    class: "center"
  }, [
    (_ctx.register)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_IvoryLogo, { class: "center" }),
          (_ctx.errorMessage != null)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1))
            : _createCommentVNode("", true),
          _createVNode(_component_IvoryInput, {
            ref: "rEmail",
            icon: "email",
            placeholder: "Email"
          }, null, 512),
          _createVNode(_component_IvoryInput, {
            ref: "rUsername",
            icon: "username",
            placeholder: "Username"
          }, null, 512),
          _createVNode(_component_IvoryInput, {
            ref: "rPassword",
            type: "password",
            icon: "pwd",
            placeholder: "Password"
          }, null, 512),
          _createVNode(_component_IvoryInput, {
            ref: "rPasswordConf",
            type: "password",
            icon: "pwd",
            placeholder: "Password confirmation"
          }, null, 512),
          _createVNode(_component_IvoryButton, {
            ref: "registerBtn",
            onClick: _ctx.registerClick,
            icon: "user"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Create an account")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_IvoryButton, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.register = false)),
            icon: "login"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Sign in")
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_IvoryLogo, { class: "center" }),
          (_ctx.errorMessage != null)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.errorMessage), 1))
            : _createCommentVNode("", true),
          _createVNode(_component_IvoryInput, {
            ref: "login",
            icon: "account",
            placeholder: "Email / Username"
          }, null, 512),
          _createVNode(_component_IvoryInput, {
            ref: "password",
            type: "password",
            icon: "pwd",
            placeholder: "Password"
          }, null, 512),
          _createVNode(_component_IvoryButton, {
            ref: "loginBtn",
            onClick: _ctx.loginClick,
            icon: "login"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Sign in")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_IvoryButton, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.register = true)),
            icon: "user"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Register")
            ]),
            _: 1
          })
        ]))
  ], 4))
}