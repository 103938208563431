export default class GenreRecord {
  public id: number = 0;

  public name: string = "";

  public description: string = "";

  public illustrationUrl: string = "";

  constructor(
    id: number,
    name: string,
    description: string,
    illustrationUrl: string
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.illustrationUrl = illustrationUrl;
  }

  static fromJson(data: any) {
    return new GenreRecord(
      data.id,
      data.name,
      data.description,
      data.illustrationUrl
    );
  }
}
