import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-892bf3c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item light" }
const _hoisted_2 = { class: "item-row" }
const _hoisted_3 = { class: "cropped" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "meta-row" }
const _hoisted_6 = { class: "f10" }
const _hoisted_7 = {
  style: {"gap":"0px"},
  class: "item-row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IvoryIcon = _resolveComponent("IvoryIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          class: "artist-img",
          src: _ctx.artist?.picture != null ? _ctx.artist.picture : '/ico/account.svg'
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("b", null, _toDisplayString(_ctx.artist!.name), 1),
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.artist.songCount) + " tracks", 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_IvoryIcon, { icon: "play-2" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.artist!.plays), 1)
        ]),
        _: 1
      })
    ])
  ]))
}