
import { defineComponent, ref } from "vue";
import { getState } from "@/state/IvoryState";
import * as DOMUtils from "@/utils/DOMUtils";
import * as ColorUtils from "@/utils/ColorUtils";
import * as IvoryApi from "@/IvoryApi";
import { PlayerState } from "@/state/PlayerState";

export default defineComponent({
  expose: ["toggle"],
  data() {
    return {
      state: getState(),
      PlayerState: PlayerState,
    };
  },
  methods: {
    async updateSettings() {
      await IvoryApi.updateAccountSettings(this.state.settings);
    },

    toggle() {
      var state = getState();
      var refs = <any>this.$refs;
      refs.colorPicker.setValue(
        ColorUtils.rgbToHex(state.noteR, state.noteG, state.noteB)
      );
      refs.leftHandColorPicker.setValue(
        ColorUtils.rgbToHex(state.noteLHR, state.noteLHG, state.noteLHB)
      );

      refs.bar.toggle();
    },
    onLeftHandColorChange() {
      var refs = <any>this.$refs;
      var color = ColorUtils.hexToRgb(refs.leftHandColorPicker.getValue())!;
      this.state.updateLHNoteColor(color.r, color.g, color.b);
      this.state.settings.notesColorLeftHand =
        refs.leftHandColorPicker.getValue();
    },
    async onNoteColorChange() {
      var refs = <any>this.$refs;
      var color = ColorUtils.hexToRgb(refs.colorPicker.getValue())!;
      this.state.updateNoteColor(color.r, color.g, color.b);
      this.state.settings.notesColor = refs.colorPicker.getValue();
    },
  },
  components: {},
});
