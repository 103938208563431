
import { defineComponent } from "vue";
import { getState } from "@/state/IvoryState";
import { HalfCircleSpinner } from "epic-spinners";
import * as IvoryApi from "@/IvoryApi";
import SearchResults from "@/model/SearchResults";
import IvorySong from "@/model/songs/IvorySong";
import * as ColorUtils from "@/utils/ColorUtils";
import { getHomeState } from "@/state/HomeState";
import SearchPage from "@/components/home/SearchPage.vue";
import TranscriptionPage from "@/components/home/TranscriptionPage.vue";
import HomeBar from "@/components/HomeBar.vue";
import { HomePageEnum } from "@/state/HomePageEnum";
import ChordsPage from "@/components/home/ChordsPage.vue";
import ProfilePage from "@/components/home/ProfilePage.vue";
import FavoritesPage from "@/components/home/FavoritesPage.vue";
import * as Modals from "@/utils/Modals";

export default defineComponent({
  expose: ["openDownloadModal"],
  components: {
    HalfCircleSpinner,
    SearchPage,
    TranscriptionPage,
    ProfilePage,
    ChordsPage,
    FavoritesPage,
  },

  computed: {
    container(): any {
      return {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${this.appState.backgroundUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: "24px",
        overflow: "scroll",
      };
    },
  },

  mounted() {},

  methods: {
    openDownloadModal(song: IvorySong) {
      var refs = <any>this.$refs;
      refs.downloadModal.open(song);
    },
  },
  data() {
    return {
      HomePageEnum: HomePageEnum,
      state: getHomeState(),
      appState: getState(),
    };
  },
});
