import { h, render } from "vue";
import SeModal from "@/components/global/IvoryModal.vue";
import DownloadModal from "@/components/home/DownloadModal.vue";
import { random } from "lodash";
import IvorySong from "@/model/songs/IvorySong";

export function open(text: string, title: string = "Infos") {
  const modalContainer = document.createElement("div");
  document.body.appendChild(modalContainer);

  const snackbarComponent = h(SeModal, { title: title });

  render(snackbarComponent, modalContainer);

  let cmp = <any>snackbarComponent.component!;

  let span = document.createElement("span");
  span.classList.add("text-muted");
  span.innerHTML = text;

  cmp.proxy.$el.querySelector(".modal-body").appendChild(span);

  cmp.proxy!.open();

  return cmp.proxy!;
}
