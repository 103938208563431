import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e6750dda"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "slider-container" }
const _hoisted_2 = ["min", "max", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      step: "0.01",
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
      type: "range",
      min: _ctx.min,
      max: _ctx.max,
      value: _ctx.modelValue,
      class: "slider"
    }, null, 40, _hoisted_2)
  ]))
}