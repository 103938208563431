export default class ProcessingTask {
  public taskId: number = 0;

  public youtubeUrl: string = "";

  public status: string = "";

  public title: string = "";

  public resultSongId: number | null = null;

  public queuePosition: number = 0;

  public queueTotal: number = 0;
}
