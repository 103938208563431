import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.color == _ctx.KeyColor.White)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        onMousedown: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClick())),
        class: "white-key"
      }, _toDisplayString(_ctx.state.settings.displayKeyNames || _ctx.inputCounts > 0 ? _ctx.label : ""), 33))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        onMousedown: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClick())),
        class: "black-key"
      }, _toDisplayString(_ctx.state.settings.displayKeyNames || _ctx.inputCounts > 0 ? _ctx.label : ""), 33))
}