
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    text: {
      type: Boolean,
      required: false,
      default: true,
    },
    sizeMul: {
      type: Number,
      required: false,
      default: 1,
    },
  },
});
