
import { defineComponent } from "vue";

export default defineComponent({
  expose: ["source"],
  methods: {},
  data() {
    var source = "";
    if (this.src != null) {
      source = this.src;
    }
    source = "/ico/" + this.icon + ".svg";

    return {
      source: source,
    };
  },
  props: {
    icon: {
      type: String,
      required: false,
      default: null,
    },
    src: {
      type: String,
      required: false,
      default: null,
    },
    size: {
      type: Number,
      required: false,
      default: 25,
    },
  },
});
