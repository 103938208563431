import * as ChordManager from "@/managers/ChordManager";

export function getNoteRelativeNumber(num: number) {
  var name = ChordManager.getNoteName(num);

  if (name.length > 1) {
    name = name[0];
  }

  const noteNames = ["A", "B", "C", "D", "E", "F", "G"];

  let index = noteNames.indexOf(name);

  let octave = getOctaveFromKeyNumber(num);

  return index + 7 * octave;
}
function getOctaveFromKeyNumber(keyNumber) {
  if (keyNumber < 1 || keyNumber > 88) {
    throw new Error("Key number must be between 1 and 88");
  }

  // MIDI note numbers start from 21 (A0) to 108 (C8)
  const baseNoteNumber = 21; // A0 is 21 in MIDI numbering
  const midiNoteNumber = baseNoteNumber + (keyNumber - 1);

  // Calculate octave number based on MIDI note number
  const octaveNumber = Math.floor((midiNoteNumber - 21) / 12) + 1;

  return octaveNumber;
}
