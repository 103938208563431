
import { defineComponent } from "vue";

export default defineComponent({
  expose: ["getValue", "setValue"],
  props: {
    placeholder: {
      type: String,
      required: false,
      default: "Input here",
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    modelValue: {},
    readyDelay: {
      type: Number,
      required: false,
      default: 400,
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },

    ready: null,
    textChange: null,
  },

  data() {
    return {
      timeout: 0,
    };
  },

  methods: {
    setValue(value: string) {
      return (this.getInputElement().value = value);
    },
    getValue() {
      return this.getInputElement().value;
    },
    getInputElement() {
      return this.$el.childNodes[1];
    },

    onChangeDelayed() {
      if (this.ready != null) {
        this.ready(this.getValue());
      }
    },
    onInput() {
      var instance = this;

      clearTimeout(this.timeout); // Clear any existing timeout

      this.timeout = setTimeout(function () {
        instance.getInputElement().dispatchEvent(new Event("change"));
      }, this.readyDelay); // Set a new 3-second timeout

      if (this.textChange != null) {
        this.textChange(this.getValue());
      }

      this.$emit("update:modelValue", this.getValue());
    },
  },
});
