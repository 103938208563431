export let BlackKeyCount: number = 36;

export let WhiteKeyCount: number = 52;

export let KeyCount: number = BlackKeyCount + WhiteKeyCount;

export let DiscordLink = "https://discord.gg/qjQ3WeJBkk";

export let InstagramLink = "https://www.instagram.com/ivory.app/";

export let TiktokLink = "https://www.tiktok.com/@ivory.app";
